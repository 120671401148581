@import "~materialize-css/dist/css/materialize.min.css";

.sidenav {
    width: auto;
}

/* классы для партнера */
/* .navbar-style {
    background-color: chartreuse;
}

.text-style a{
    color: black
}

.btn-style


*/
